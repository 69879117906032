import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LogOut, User, ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const UserMenu = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        onLogout();
        window.location.reload();
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const getDiscordAvatar = (userId, avatarId) => {
    return avatarId 
      ? `https://cdn.discordapp.com/avatars/${userId}/${avatarId}.png` 
      : 'https://cdn.discordapp.com/embed/avatars/0.png';
  };

  return (
    <div className="relative">
      <motion.button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 p-1 rounded-lg hover:bg-gray-800 transition-colors"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <div className="relative">
          <img
            src={getDiscordAvatar(user.discordId, user.avatar)}
            alt={user.username}
            className="w-8 h-8 rounded-full ring-2 ring-indigo-500"
          />
          <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full border-2 border-gray-900" />
        </div>
        <span className="text-white font-medium hidden md:block">{user.username}</span>
        <ChevronDown 
          className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            
            <motion.div
              className="absolute right-0 mt-2 w-48 rounded-lg bg-gray-800 shadow-lg ring-1 ring-gray-700 z-50 overflow-hidden"
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -10, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              <div className="p-2 border-b border-gray-700">
                <p className="px-3 py-2 text-sm text-gray-400">Signed in as</p>
                <p className="px-3 py-1 text-sm font-medium text-white truncate">{user.username}</p>
                {user.customerHistory.hasBeenCustomer && (
                  <p className="px-3 py-1 text-xs text-indigo-400">Customer since {new Date(user.customerHistory.firstPurchaseDate).toLocaleDateString()}</p>
                )}
              </div>
              
              <div className="p-2 space-y-1">
              <button
                className="w-full px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-md flex items-center space-x-2 transition-colors"
                onClick={() => {
                    setIsOpen(false);
                    navigate('/profile');
                }}
              >
              <User className="w-4 h-4" />
              <span>Profile</span>
              </button>
                
                {/* {user.currentSubscription.isActive && (
                  <button
                    className="w-full px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-md flex items-center space-x-2 transition-colors"
                    onClick={() => {
                      setIsOpen(false);
                      navigate('/dashboard');
                    }}
                  >
                    <Code className="w-4 h-4" />
                    <span>Dashboard</span>
                  </button>
                )} */}
              </div>
              
              <div className="p-2 border-t border-gray-700">
                <button
                  onClick={handleLogout}
                  className="w-full px-3 py-2 text-sm text-red-400 hover:bg-gray-700 rounded-md flex items-center space-x-2 transition-colors"
                >
                  <LogOut className="w-4 h-4" />
                  <span>Logout</span>
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserMenu;