import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Bot, ChevronRight, ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { siteConfig } from '../config';
import { DiscordIcon } from './icons/DiscordIcon';
import UserMenu from './UserMenu';

const handleLogin = () => {
  window.location.href = 'https://discord.com/oauth2/authorize?client_id=1324145260376621217&response_type=code&redirect_uri=https%3A%2F%2Fstorption.co.uk%2Fauth%2Fcallback&scope=identify+email+guilds';
};

const Navigation = ({ currency, setCurrency, setShowContact }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const isMainPage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      }
      setIsLoadingUser(false);
    };
  
    checkAuthStatus();
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      setIsMenuOpen(false);
      setTimeout(() => {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  };

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed w-full z-50 transition-all duration-300 relative ${
        isScrolled 
          ? 'bg-gray-900/95 backdrop-blur-xl border-b border-gray-800/50 shadow-lg' 
          : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center space-x-4">
            {!isMainPage && (
              <motion.button
                onClick={() => navigate('/')}
                className="text-gray-400 hover:text-white flex items-center"
                whileHover={{ x: -5 }}
              >
                <ArrowLeft className="w-5 h-5" />
                <span className="ml-2">Back Home</span>
              </motion.button>
            )}
            
            <motion.div 
              className="flex items-center space-x-2 cursor-pointer"
              whileHover={{ scale: 1.05 }}
              onClick={() => setShowContact(true)}
            >
              <div className="relative">
                <div className="absolute inset-0 bg-indigo-500 blur-lg opacity-50"></div>
                <Bot className="w-8 h-8 text-white relative" />
              </div>
              <span className="text-2xl font-bold bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">
                {siteConfig.siteName}
              </span>
            </motion.div>
          </div>

          {isMainPage && (
            <>
              <div className="hidden md:flex items-center space-x-4">
                <button
                  onClick={() => scrollToSection('features')}
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Features
                </button>
                <button
                  onClick={() => scrollToSection('pricing')}
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Pricing
                </button>
                <button
                  onClick={() => scrollToSection('faq')}
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  FAQ
                </button>
                {/* <Link
                  to="/blog"
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Blog
                </Link> */}
                <Link 
                  to="/insights"
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  Insights
                </Link>

                <div className="relative">
                  <motion.button
                    onClick={() => setIsCurrencyOpen(!isCurrencyOpen)}
                    className="flex items-center space-x-2 px-4 py-2 bg-gray-800 rounded-lg text-white hover:bg-gray-700 transition-colors"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <span>{currency}</span>
                    <ChevronRight 
                      className={`w-4 h-4 transition-transform ${isCurrencyOpen ? 'rotate-90' : ''}`}
                    />
                  </motion.button>

                  <AnimatePresence>
                    {isCurrencyOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute right-0 mt-2 w-32 rounded-lg shadow-lg bg-gray-800 ring-1 ring-gray-700 overflow-hidden"
                      >
                        {Object.keys(siteConfig.currencies).map((curr) => (
                          <button
                            key={curr}
                            onClick={() => {
                              setCurrency(curr);
                              setIsCurrencyOpen(false);
                            }}
                            className={`w-full px-4 py-3 text-left transition-colors ${
                              currency === curr 
                                ? 'bg-gray-700 text-indigo-400' 
                                : 'text-gray-300 hover:bg-gray-700'
                            }`}
                          >
                            {curr} ({siteConfig.currencies[curr].symbol})
                          </button>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => scrollToSection('pricing')}
                  className="px-6 py-2.5 bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-all duration-300"
                >
                  Get Started
                </motion.button>

                {isLoadingUser ? (
                  <div className="w-8 h-8 rounded-full bg-gray-700 animate-pulse" />
                ) : user ? (
                  <UserMenu 
                    user={user} 
                    onLogout={() => setUser(null)} 
                  />
                ) : (
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleLogin}
                    className="flex items-center gap-2 px-6 py-2.5 bg-[#5865F2] hover:bg-[#4752C4] rounded-lg transition-colors relative group"
                  >
                    <motion.div
                      className="relative"
                      whileHover={{
                        rotate: 360,
                        transition: { duration: 0.5, ease: "easeInOut" }
                      }}
                    >
                      <DiscordIcon className="w-5 h-5" color="white" />
                    </motion.div>
                    <span className="relative">Login</span>
                  </motion.button>
                )}
              </div>

              {/* Mobile Menu */}
              <div className="md:hidden flex items-center gap-3">
                {isLoadingUser ? (
                  <div className="w-8 h-8 rounded-full bg-gray-700 animate-pulse" />
                ) : user ? (
                  <UserMenu 
                    user={user} 
                    onLogout={() => setUser(null)} 
                  />
                ) : (
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleLogin}
                    className="flex items-center gap-2 px-4 py-2 bg-[#5865F2] hover:bg-[#4752C4] rounded-lg transition-colors relative group"
                  >
                    <DiscordIcon className="w-5 h-5" color="white" />
                    <span>Login</span>
                  </motion.button>
                )}

                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="p-2 rounded-lg bg-gray-800"
                >
                  {isMenuOpen ? <X /> : <Menu />}
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {isMainPage && (
        <motion.div
          initial={false}
          animate={{
            height: isMenuOpen ? 'auto' : 0,
            opacity: isMenuOpen ? 1 : 0
          }}
          transition={{ duration: 0.2 }}
          className="md:hidden overflow-hidden bg-gray-900/95 backdrop-blur-md"
        >
          <div className="px-4 py-4 space-y-3">
            <button
              onClick={() => scrollToSection('features')}
              className="block w-full text-left text-gray-300 hover:text-white py-2"
            >
              Features
            </button>
            <button
              onClick={() => scrollToSection('pricing')}
              className="block w-full text-left text-gray-300 hover:text-white py-2"
            >
              Pricing
            </button>
            <button
              onClick={() => scrollToSection('faq')}
              className="block w-full text-left text-gray-300 hover:text-white py-2"
            >
              FAQ
            </button>
            {/* <Link 
              to="/blog"
              className="block w-full text-left text-gray-300 hover:text-white py-2"
              onClick={() => setIsMenuOpen(false)}
            >
              Blog
            </Link> */}
            <Link 
              to="/insights"
              className="block w-full text-left text-gray-300 hover:text-white py-2"
              onClick={() => setIsMenuOpen(false)}
            >
              Insights
            </Link>
            <button 
              onClick={() => scrollToSection('pricing')}
              className="w-full px-6 py-3 bg-indigo-600 hover:bg-indigo-700 rounded-lg text-center text-white"
            >
              Get Started
            </button>
          </div>
        </motion.div>
      )}
    </motion.nav>
  );
};

export default Navigation;